.section {
    padding: 10px 10px 40px 10px;
    margin: 20px 0 20px 0;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, transparent 0%, #ffffff 50%, transparent 100%) 1;
}

.inputkey {
    background-color: #4E4B47;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    border-radius: 10px;
    color: white;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    margin: 10px;
  
    &:active, &:focus { 
      border: 1px solid #fed6e3;
      outline: none; 
}
}

.inputkey::placeholder {
    color: white;
  }

.usersTilts-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.usersTilts {
    width: fit-content;
    margin: 5px;
    padding: 2px;
    text-align: right;
}

.sharingKey {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    width: fit-content;
    margin: 30px auto 0 auto;
    padding: 10px;
}

.key {
    text-decoration: underline;
    font-style: italic;
    margin: 0 15px;
}