.homepage {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Wyśrodkowanie elementów */
    min-height: 100vh;
    /* Minimalna wysokość strony na pełny ekran */
    padding: 0;
    /* Usuń niepotrzebne paddingi */
    margin: 0;
    /* Usuń globalne marginesy */
}

.card-homepage {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.mt-4 {
    margin-top: 1.5rem;
    /* Zachowaj minimalny odstęp, ale usuń niepotrzebne duże marginesy */
}

.homepage h1 {
    font-size: 6.3rem;
    margin: 0;
    /* Usuń domyślny margines nagłówka */
    padding-top: 20px;
    /* Dodaj nieco przestrzeni nad h1, jeśli potrzebne */
}

.homepage h2 {
    padding-bottom: 8vh;
    margin: 0;
    /* Usuń margines nad h2 */
}

.register {
    padding-bottom: 30px;
}

/* Responsywność dla mniejszych ekranów */
@media (max-width: 1024px) {
    .homepage {
        justify-content: flex-start;
        /* Pozycjonowanie zaczyna się od góry */
        padding-top: 20px;
        /* Dodaj górny padding */
    }

    .homepage h1 {
        font-size: 4.5rem;
        /* Mniejsza czcionka dla ekranów 1024px */
    }

    .homepage h2 {
        font-size: 2.5rem;
        padding-bottom: 6vh;
    }

    .register {
        padding-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .homepage {
        justify-content: flex-start;
        /* Wyśrodkowanie horyzontalne, ale pozycjonowanie zaczyna się od góry */
        padding-top: 20px;
        /* Dodaj górny padding dla kompensacji */
    }

    .homepage h1 {
        font-size: 3.5rem;
    }

    .homepage h2 {
        font-size: 2rem;
        padding-bottom: 5vh;
    }

    .register {
        padding-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .homepage h1 {
        font-size: 2.5rem;
    }

    .homepage h2 {
        font-size: 1.5rem;
        padding-bottom: 4vh;
    }

    .register {
        padding-bottom: 10px;
    }
}