.regField {
    width: 98%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .regField h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .regField input[type="text"],
  .regField input[type="email"],
  .regField input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .regField input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .regField input[type="submit"]:hover {
    background-color: #0056b3;
  }

.acceptEULA {
    color: rgb(55, 55, 55);
    text-decoration: underline;
  }
  