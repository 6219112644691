.document {
    width: 80%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8fafc;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
h1, h2 {
    color: #333;
}
h1 {
    text-align: center;
}
p {
    margin: 10px 0;
}
ul {
    margin: 10px 0;
    padding-left: 20px;
}

.item {
    background-color: rgba(255, 255, 255, 0.3);
    margin: 20px;
}