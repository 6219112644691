.container-reset-pass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.form {
  width: 100%;
}

.inputkey {
    background-color: #4E4B47;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    border-radius: 10px;
    color: white;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    margin: 10px;
    width: 100%;
  
    &:active, &:focus { 
      border: 1px solid #fed6e3;
      outline: none; 
}
}

.inputkey::placeholder {
    color: white;
  }
