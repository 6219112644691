  .logField {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
  }
  
  .logField h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logField input[type="text"],
  .logField input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .logField input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logField input[type="submit"]:hover {
    background-color: #ffffff;
  }

  .registerButton {
    justify-content: center;
    text-align: center;
    padding-top: 40px;
  }

  .registerButton Button {
    margin-top: 20px;
  }
  