/* Ustawienie wysokości całego sidebaru */
.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centrowanie wertykalne */
    align-items: center;  
    height: 100vh;
    padding: 20px;
    background: radial-gradient(circle, #28a998 0%, #25493e 100%);
}

.sidebar-top-buttons {
    flex-shrink: 0;
}

.sidebar-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sidebar-footer {
    flex-shrink: 0;
    text-align: center;
    justify-content: space-between;
    border-top: 1px solid;
    border-image: linear-gradient(to right, transparent 0%, #ffffff 50%, transparent 100%) 1;
    width: 96%;
}

.sidebar-footer a {
    display: block;
    margin: 5px 0;
    color: #333;
    text-decoration: none;
}

.sidebar-footer a:hover {
    text-decoration: underline;
}

.copyright {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
}

@media (max-width: 1024px) {
    .sidebar {
        height: fit-content;
        padding: 10px;
    }

    .sidebar-footer {
        flex-direction: column;
        text-align: center;
    }

    .sidebar-footer a {
        margin: 5px 0;
    }
}
